import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash'
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python'
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'

import { useTranslate } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { Button } from '/features/buildingBlocks/Button'

import styles from './CodeBlock.css'

SyntaxHighlighter.registerLanguage('Bash', bash)
SyntaxHighlighter.registerLanguage('Python', python)

export function CodeBlock({ code, language }) {
  const reportError = useReportError()
  const [copied, setCopied] = React.useState(false)
  const { __ } = useTranslate()

  React.useEffect(
    () => {
      let timeout

      if (copied) {
        timeout = setTimeout(() => {
          setCopied(false)
        }, 3000)
      }

      return () => clearTimeout(timeout)
    },
    [copied]
  )

  return (
    <div className={styles.component} data-style-context='orange'>
      <SyntaxHighlighter
        language={language || 'text'}
        style={a11yDark}
        customStyle={{
          margin: '0',
          minHeight: 'calc(var(--size-40) + var(--size-25) * 2)',
          background: 'var(--color-green-deep)',
          borderRadius: '12px',
          padding: 'var(--size-25)',
        }}
      >
        {code || ''}
      </SyntaxHighlighter>
      <Button
        onClick={handleClick}
        dataX="code-block-copy"
        layoutClassName={styles.copyButtonLayout}
      >
        {copied ? <>{__`code-block-copied`}</> : <>{__`code-block-copy`}</>}
      </Button>
    </div>
  )

  function handleClick() {
    if (!window.navigator || copied) return

    navigator.clipboard.writeText(code).catch(reportError)
    setCopied(true)
  }
}
